#events-container {
    margin: 30px auto;
    display: flex;
    width: 1000px;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media only screen and (max-width:1250px) {
    #events-container {
        width: 95%;
        margin-top: 25px;
    }
}

