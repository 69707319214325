.scroll-gift{
    position: relative;
    bottom:20px;
}
@media only screen and (max-width:1170px){
    .scroll-gift{
       display: none;
    }
}
#sec-2 {
    background-color: aliceblue;
  }
  .scroll-down {
    height: 50px;
    width: 30px;
    border: 2px solid rgb(247, 108, 57);
    position: absolute;
    left: 50%;
    border-radius: 50px;
    cursor: pointer;
  }
  .scroll-down::before,
  .scroll-down::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border: 2px solid black;
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1s ease-in-out infinite;
  }
  .scroll-down::before {
    top: 30%;
    animation-delay: 0.3s;
    /* animation: scroll-down 1s ease-in-out infinite; */
  }
  
  @keyframes scroll-down {
    0% {
      /* top:20%; */
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      top: 90%;
      opacity: 0;
    }
  }