.lds-dual-ring {
  /* margin: auto; */
  /* width: 20px;
  height: 20px; */
  margin-top: 6px;
  margin-bottom: 6px;
  
    /* background-color: var(--my-after-color); */
    /* Diğer stil özellikleri burada eklenebilir */
  
  
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 80%;
  border: 2.5px solid var(--my-after-color);
  border-color: var(--my-after-color) transparent var(--my-after-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}