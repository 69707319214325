* {
  padding: 0;
  margin: 0;
}

#pages {
  min-height: 700px;
}

#loader-container {
  min-height: 600px;
  padding: 100px;
}

/* ------------------------------- toptoScroll Button style ------------------------*/
#topToScrollButton {

  background: rgba(6, 5, 5, 0.4);
  border-radius: 100px;
  color: white;
  text-align: center;
  padding: 8px 10px;
}

@media only screen and (max-width:700px) {
  #topToScrollButton {
    padding: 5px 7px;
  }
}


/* -----------------------------------------Header Style */
header {
  display: flex;
  justify-content: space-around;
  background-color: rgb(250, 95, 39);
  height: 80px;
  position: relative;
}

#nav-header {
  display: flex;
  align-items: center;
}

#nav-header #nav-home,
#nav-auth {
  display: flex;
}

#nav-auth {
  position: relative;
  cursor: pointer;
  z-index: 4;
  margin-left: 10px;
}

#auth-link-box {
  display: none;
  width: 120px;
  border: 1px solid lightgray;
  position: absolute;
  left: -15px;
  top: 45px;
  z-index: 4;
  background-color: white;
  border-radius: 10px;
  padding: 5px 8px;
}

#auth-link-box.open {
  display: flex;
  flex-direction: column;
}


#auth:hover~#auth-link-box {
  display: flex;
  flex-direction: column;
}

#auth-link-box:hover {
  display: flex;
  flex-direction: column;
}

#on-hover-auth:hover~#auth-link-box {
  display: flex;
  flex-direction: column;
}

#on-hover-auth {
  width: 90px;
  height: 50px;
  /* border:1px white solid; */
  position: absolute;
}

.triangle {
  position: absolute;
  top: -19px;
  left: 55px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(255, 255, 255);
  border-top: 10px solid transparent;
  display: inline-block;
}

.auth-link {
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  color: black;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 6px 13px;
  margin-top: 7px;
}

.logout-link {
  text-decoration: none;
  color: gray;
  font-size: 15px;
  margin-top: 5px;
  padding: 3px;
  border-radius: 8px;
}

.logout-link:hover,.auth-link:hover {
  background: rgb(241, 211, 163);
}

#nav-home a {
  z-index: 3;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 12px;
  color: rgb(225, 224, 224);
  font-weight: 500;
  font-family: system-ui;
}

#nav-home a:hover {
  color: white;
}


#user-img {
  z-index: 3;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}


.logo {
  z-index: 3;
}

#logo {
  width: 190px;
  margin-top: 24px;
}

#nav-auth p {
  margin-top: 3px;
  padding-left: 5px;
  color: white;
  font-family: system-ui;
  font-weight: 500;
  font-size: 13px;
}



@media only screen and (max-width:850px) {
  #nav-auth>a {
    margin-left: 0px;
    margin-right: 10px;
  }
}

@media only screen and (max-width:850px) {
  #logo {
    width: 150px;
    margin-top: 27px;
  }

  #nav-header a,
  #nav-auth p {
    font-size: 13px;
    font-weight: 500;
  }

  #nav-home {
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    top: 82px;
    right: 3px;
    background-color: rgb(250, 95, 39);
    border-bottom-left-radius: 20px;
  }

  #nav-home a {
    padding: 15px 75px;
  }

  #nav-home.open {
    visibility: visible;
    z-index: 3;
  }
}

/*                                             Hamburher Menu     */
#burger-menu {
  margin-left: 20px;
  display: none;
  z-index: 5;
}

.line {
  border-radius: 20px;
  height: 4px;
  margin: 5px;
  background-color: white;
  width: 25px;
}

#line2 {
  width: 25px;
}

@media only screen and (max-width:850px) {
  #burger-menu {
    display: block;
  }

  .close {
    padding-top: 5px;
  }

  .close #line1 {
    transform: rotate(-43deg) translate(-9px, 1px);
    transition: .3s;
  }

  .close #line2 {
    opacity: 0;
  }

  .close #line3 {
    transform: rotate(40deg) translate(-12px, -5px);
    transition: .3s;
  }
}

@media only screen and (max-width:350px) {
  #logo {
    width: 140px;
    /* margin-top: -29px; */
    margin-left: -10px;
  }
}